<template>
  <div>
    <v-container class="pt-0 pb-0">
      <v-row>
        <v-col lg="12" class="pb-1">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col lg="12">
                  <v-label> Carga Masiva de Actualizacion de Detracciones </v-label>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="s-col-form" sm="12" md="12">
                  <s-import-excel label="Leer archivo excel" @clear="itemsDetraction = []" @changeData="changeFile($event)"></s-import-excel>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="s-col-form" sm="12" md="12">
                  <v-data-table
                    dense
                    :single-select="true"
                    :headers="headers"
                    @click:row="rowClick"
                    :items="itemsImport"
                    locale="es-ES"
                    selectable-key="isSelectable"
                    :items-per-page="10"
                    item-key="ID"
                    ref="stable"
                    v-model="selected"
                    :footer-props="{
                      showCurrentPage: true,
                      showPageText: true,
                      showFirstLastPage: false,
                      showPerPageOptions: true,
                      showPerPageText: true,
                      itemsPerPageAllText: 'Todos',
                      noResultsText: 'sin resultados',
                      noDataText: 'sin resultados',
                      itemsPerPageOptions: [10, 25, 50, -1],
                      itemsPerPageText: 'Registros',
                    }"
                  >
                    <template slot:item.WacStatus="{ item }"> </template>
                    <template slot="no-data"> Sin Datos </template>
                    <template slot="no-results"> Sin Datos </template>
                    <template slot:footer.page-text="{ pageStop, itemsLength }">
                      {{ itemsLength }}
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>
            <v-card-actions>
              <v-col class="s-col-form" @click="cancel()"><v-btn block text>Cancelar</v-btn></v-col>
              <v-col class="s-col-form"
                ><v-btn block color="primary" @click="save()"> <v-icon small left>fas fa-save</v-icon>Guardar</v-btn></v-col
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col lg="12" class="pt-0">
          <v-card elevation="0" class="title">
            <v-tabs centered height="35" @change="changeTab($event)" v-model="currentItem">
              <v-tab v-for="item in items" :key="item" :href="'#tab-' + item"> {{ item }}</v-tab>
            </v-tabs>
            <v-tabs-items v-model="currentItem" style="padding: 10 10px 10px 0px">
              <v-tab-item v-for="item in items" :key="item" :value="'tab-' + item">
                <v-card flat tile elevation="0">
                  <v-data-table
                    dense
                    :single-select="true"
                    :headers="headersPayments"
                    @click:row="rowClick"
                    :items="itemsPayments"
                    locale="es-ES"
                    selectable-key="isSelectable"
                    :items-per-page="10"
                    item-key="ID"
                    ref="stable"
                    v-model="selected"
                    :footer-props="{
                      showCurrentPage: true,
                      showPageText: true,
                      showFirstLastPage: false,
                      showPerPageOptions: true,
                      showPerPageText: true,
                      itemsPerPageAllText: 'Todos',
                      noResultsText: 'sin resultados',
                      noDataText: 'sin resultados',
                      itemsPerPageOptions: [10, 25, 50, -1],
                      itemsPerPageText: 'Registros',
                    }"
                  >
                    <template slot:item.WacStatus="{ item }"> </template>
                    <template slot="no-data"> Sin Datos </template>
                    <template slot="no-results"> Sin Datos </template>
                    <template slot:footer.page-text="{ pageStop, itemsLength }">
                      {{ itemsLength }}
                    </template>
                  </v-data-table>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  props: {},
  data: () => ({
    tabSelected: "",
    items: ["Pagos", "Cuentas"],

    itemsImport: [],
    itemsPayments: [],
    itemsAccount: [],
    itemsLength: 0,
    selected: [],
    headers: [
      { text: "Fecha", value: "Fecha", column: "FECHA", date: true },
      { text: "Cuenta", value: "Cuenta", column: "CUENTA " },
      { text: "Comentario", value: "Comentario", column: "COMENTARIO" },
      { text: "Importe", value: "Importe", column: "IMPORTE CON DECIMAL" },
      { text: "Cuenta de Pago", value: "Cuenta" },
      { text: "Monto", value: "Monto", column: "MONTO REDONDEADO" },
      { text: "Centro Costo", value: "Centro" },
      { text: "Linea de Negocio", value: "Linea", column: "LINEA DE NEGOCIO" },
      { text: "Tipo Costo", value: "Tipo", column: "TIPO DE COSTO" },
      { text: "Medio Pago", value: "Medio", column: "MEDIO DE PAGO" },
      { text: "Tipo Operación", value: "Operacion", column: "TIPO DE OPERACIÓN PAGO" },
      { text: "Cod", value: "Codigo", column: "CODIGO DE OPERACIÓN" },
    ],
    headersPayments: [
      { text: "DocNum", value: "DocNum" },
      { text: "DocType", value: "DocType" },
      { text: "DocDate", value: "DocDate" },
      { text: "DocCurr", value: "DocCurr" },
      { text: "TrsfrAcct", value: "TrsfrAcct" },
      { text: "TrsfrSum", value: "TrsfrSum" },
      { text: "TrsfrDate", value: "TrsfrDate" },
      { text: "TrsfrRef", value: "TrsfrRef" },
      { text: "Comments", value: "Comments" },
      { text: "JrnlMemo", value: "JrnlMemo" },
      { text: "TaxDate", value: "TaxDate" },
      { text: "Series", value: "Series" },
      { text: "DocDueDate", value: "DocDueDate" },
      { text: "U_SYP_MPPG", value: "U_SYP_MPPG" },
      { text: "U_SYP_TPOOPER", value: "U_SYP_TPOOPER" },
    ],
    headersAccounts: [{ text: "Fecha", value: "Fecha", column: "FECHA", date: true }],
  }),
  methods: {
    changeTab(n) {
      this.tabSelected = n;
      // si es description seleccione funciones
      if (n == "tab-Pagos") this.tabSelected = "tab-Pagos";
      if (n == "tab-Cuentas") this.tabSelected = "tab-Cuentas";
    },
    rowClick: function (item, row) {
      this.selected = [item];
      this.row = Object.assign({}, item);
      this.$emit("rowSelected", this.selected);
    },
    changeFile(data) {
      let item = {};
      var i = 0;
      data.forEach((element) => {
        item = {
          Fecha: this.$fun.getDate(element.FECHA),
          Cuenta: data[i]["CUENTA"],
          Comentario: data[i]["COMENTARIO"],
          Importe: data[i]["IMPORTE CON DECIMAL"],
          Cuenta: data[i]["CUENTA DE PAGO"],
          Monto: data[i]["MONTO REDONDEADO"],
          Centro: data[i]["CENTRO DE COSTO"],
          Linea: data[i]["LINEA DE NEGOCIO"],
          Tipo: data[i]["TIPO DE COSTO"],
          Medio: data[i]["MEDIO DE PAGO "],
          Operacion: data[i]["TIPO DE OPERACIÓN PAGO"],
          Codigo: data[i]["CODIGO DE OPERACIÓN"],
        };
        i++;
        item.ID = this.itemsImport.length + 1;
        this.itemsImport.push(item);
      });
    },
  },
};
</script>
