var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',{staticClass:"pt-0 pb-0"},[_c('v-row',[_c('v-col',{staticClass:"pb-1",attrs:{"lg":"12"}},[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"lg":"12"}},[_c('v-label',[_vm._v(" Carga Masiva de Actualizacion de Detracciones ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"s-col-form",attrs:{"sm":"12","md":"12"}},[_c('s-import-excel',{attrs:{"label":"Leer archivo excel"},on:{"clear":function($event){_vm.itemsDetraction = []},"changeData":function($event){return _vm.changeFile($event)}}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"s-col-form",attrs:{"sm":"12","md":"12"}},[_c('v-data-table',{ref:"stable",attrs:{"dense":"","single-select":true,"headers":_vm.headers,"items":_vm.itemsImport,"locale":"es-ES","selectable-key":"isSelectable","items-per-page":10,"item-key":"ID","footer-props":{
                    showCurrentPage: true,
                    showPageText: true,
                    showFirstLastPage: false,
                    showPerPageOptions: true,
                    showPerPageText: true,
                    itemsPerPageAllText: 'Todos',
                    noResultsText: 'sin resultados',
                    noDataText: 'sin resultados',
                    itemsPerPageOptions: [10, 25, 50, -1],
                    itemsPerPageText: 'Registros',
                  }},on:{"click:row":_vm.rowClick},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[void 0,_c('template',{slot:"no-data"},[_vm._v(" Sin Datos ")]),_c('template',{slot:"no-results"},[_vm._v(" Sin Datos ")]),[_vm._v(" "+_vm._s(_vm.itemsLength)+" ")]],2)],1)],1),_c('v-divider')],1),_c('v-card-actions',[_c('v-col',{staticClass:"s-col-form",on:{"click":function($event){return _vm.cancel()}}},[_c('v-btn',{attrs:{"block":"","text":""}},[_vm._v("Cancelar")])],1),_c('v-col',{staticClass:"s-col-form"},[_c('v-btn',{attrs:{"block":"","color":"primary"},on:{"click":function($event){return _vm.save()}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("fas fa-save")]),_vm._v("Guardar")],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"lg":"12"}},[_c('v-card',{staticClass:"title",attrs:{"elevation":"0"}},[_c('v-tabs',{attrs:{"centered":"","height":"35"},on:{"change":function($event){return _vm.changeTab($event)}},model:{value:(_vm.currentItem),callback:function ($$v) {_vm.currentItem=$$v},expression:"currentItem"}},_vm._l((_vm.items),function(item){return _c('v-tab',{key:item,attrs:{"href":'#tab-' + item}},[_vm._v(" "+_vm._s(item))])}),1),_c('v-tabs-items',{staticStyle:{"padding":"10 10px 10px 0px"},model:{value:(_vm.currentItem),callback:function ($$v) {_vm.currentItem=$$v},expression:"currentItem"}},_vm._l((_vm.items),function(item){return _c('v-tab-item',{key:item,attrs:{"value":'tab-' + item}},[_c('v-card',{attrs:{"flat":"","tile":"","elevation":"0"}},[_c('v-data-table',{ref:"stable",refInFor:true,attrs:{"dense":"","single-select":true,"headers":_vm.headersPayments,"items":_vm.itemsPayments,"locale":"es-ES","selectable-key":"isSelectable","items-per-page":10,"item-key":"ID","footer-props":{
                    showCurrentPage: true,
                    showPageText: true,
                    showFirstLastPage: false,
                    showPerPageOptions: true,
                    showPerPageText: true,
                    itemsPerPageAllText: 'Todos',
                    noResultsText: 'sin resultados',
                    noDataText: 'sin resultados',
                    itemsPerPageOptions: [10, 25, 50, -1],
                    itemsPerPageText: 'Registros',
                  }},on:{"click:row":_vm.rowClick},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[void 0,_c('template',{slot:"no-data"},[_vm._v(" Sin Datos ")]),_c('template',{slot:"no-results"},[_vm._v(" Sin Datos ")]),[_vm._v(" "+_vm._s(_vm.itemsLength)+" ")]],2)],1)],1)}),1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }